import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from '../components/header'
import GetInTouch from "../components/get-in-touch"
import Footer from "../components/footer"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Header />
    <article className="page full-height">
      <div className="container">
        <h1 className="g-h1">404 - NOT FOUND</h1>
        <p className="g-p1">You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </article>
    <GetInTouch />
    <Footer/>
  </Layout>
)

export default NotFoundPage
